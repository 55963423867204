@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;
    }
}

.bg-custom-gradient {
    background: linear-gradient(257deg, #0070B6 0%, #0D466D 40.9%, #172331 99.47%);
}

.bg-gradient-consultoria{
    background: linear-gradient(76deg, #1E528A -1.01%, #2C68A9 102.17%);
}

.hero-green-bg {
    background-image: url("./../images/forma_15.png");
    background-position: bottom left;
    background-size: 100%;
    background-repeat: no-repeat;
}

.bg-element-1-left {
    background-image: url("./../images/amarelo-left.png");
    background-position: left;
    background-size: 100%;
    background-repeat: no-repeat;
}


.bg-element-1-right {
    background-image: url("./../images/amarelo-right.png");
    background-position: left;
    background-size: 100%;
    background-repeat: no-repeat;
}
.bg-element-1-left-desktop {
    background-image: url("./../images/forma.png");
    background-position: -100px 100px  ;
    background-size: 50%;

    background-repeat: no-repeat;
}
.bg-element-1-right {
    background-image: url("./../images/verde.png");
    background-position: left;
    background-size: 150%;
    background-repeat: no-repeat;
}
.bg-element-1-right-desktop {
    background-image: url("./../images/verde.png");
    background-position: center;
    background-size: 120%;
    background-repeat: no-repeat;
}

.bg-element-tablet-right{
    background-image: url("./../images/amarelo-right.png");
    background-position: left;
    background-size: 100%;
    background-repeat: no-repeat;
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }
}
